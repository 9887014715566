<template>
  <div>
    <v-container fluid>
      <v-progress-linear
        :active="loading"
        :indeterminate="loading"
        absolute
        top
        color="blue accent-4"
      ></v-progress-linear>
      <v-snackbar v-model="snackbar" :color="snackbar_color" rounded="pill" top>
        {{ text }}

        <template v-slot:action="{ attrs }">
          <v-btn color="white" icon v-bind="attrs" @click="snackbar = false">
            <v-icon>mdi-window-close</v-icon>
          </v-btn>
        </template>
      </v-snackbar>
      <v-row justify="center" align-sm="center" class="mt-1">
        <v-col sm cols="12" align-self="center" class="d-flex justify-center">
          <v-img
            alt="100 Miles Logo"
            class="shrink"
            contain
            src="../../assets/login-page-logo.png"
            transition="scale-transition"
            max-height="200"
            max-width="200"
          />
        </v-col>
        <v-col
          sm
          cols="12"
          align-self="center"
          class="
            d-flex
            justify-center
            align-center
            rounded-t-xl
            grey
            lighten-5
            flex-column
          "
        >
          <span class="primary--text mb-2 title">RESET PASSWORD</span>
          <v-divider></v-divider>
          <v-form
            v-on:submit.prevent="resetPassword"
            ref="form"
            v-model="valid"
            lazy-validation
          >
            <v-text-field
              v-model="user.email"
              :rules="emailRules"
              label="E-mail"
              prepend-inner-icon="fas fa-envelope"
              rounded
              outlined
              color="primary"
            />
            <v-text-field
              v-model="user.password"
              :rules="passwordRules"
              label="New Password"
              :counter="8"
              :type="ShowPassword ? 'text' : 'password'"
              prepend-inner-icon="fas fa-lock"
              :append-icon="ShowPassword ? 'mdi-eye' : 'mdi-eye-off'"
              @click:append="ShowPassword = !ShowPassword"
              rounded
              outlined
            />
            <v-text-field
              label="New Confirm Password"
              v-model="user.password_confirmation"
              :rules="confirmpasswordRules"
              :type="ShowConfirmPassword ? 'text' : 'password'"
              prepend-inner-icon="fas fa-lock"
              :counter="8"
              :append-icon="ShowConfirmPassword ? 'mdi-eye' : 'mdi-eye-off'"
              @click:append="ShowConfirmPassword = !ShowConfirmPassword"
              rounded
              outlined
            />

            <v-btn
              color="info"
              type="submit"
              rounded
              block
              @click="validate"
              :disabled="!valid"
            >
              RESET PASSWORD
            </v-btn>
          </v-form>
        </v-col>
        <v-col
          sm
          cols="12"
          align-self="center"
          class="
            d-flex
            justify-center
            align-center
            rounded-b-xl
            grey
            lighten-5
            flex-column
          "
        >
          <!-- <div>
                Already have an account ?
                <router-link to="/auth/login" class="routerlink "
                  >Sign In</router-link
                ></div> -->
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import Authentication from "../../api/Authentication";
export default {
  data() {
    return {
      valid: true,
      user: {
        email: "",
        token: "",
        password: "",
        password_confirmation: "",
      },
      loading: false,
      snackbar: false,
      snackbar_color: "",
      text: "",
      ShowPassword: false,
      ShowConfirmPassword: false,

      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+/.test(v) || "E-mail must be valid.",
      ],

      passwordRules: [
        (v) => !!v || "Password is required",
        (v) => v.length >= 8 || "password at least 8 characters.",
      ],

      confirmpasswordRules: [
        (v) => !!v || "Confirm Password is required",
        (v) => v.length >= 8 || "Confirm password at least 8 characters.",
        (v) => v === this.user.password || "Password dose not match",
      ],
    };
  },
  mounted() {
    this.getToken();
  },
  methods: {
    validate() {
      this.$refs.form.validate();
    },

    getToken() {
      this.user.token = this.$route.query.token;
    },
    resetPassword: function () {
      //console.log('forgot password function executed..',this.user)
      this.loading = true;
      Authentication.resetPassword(this.user)
        .then((response) => {
          //console.log('response data :',response);
          if (response.status == 200) {
            this.loading = false;
            this.snackbar = true;
            this.snackbar_color = "success";
            this.text = response.data.message;
          }
        })
        .catch((error) => {
          //console.log('Error message :', error.response.data.message);
          this.loading = false;
          this.snackbar = true;
          this.snackbar_color = "error";
          this.text = error.response.data.message;
        });
    },
  },
};
</script>
